<template>
  <el-dialog class="operation_dialog bgac_dialog abox dialog_body_top0" v-model="dialogVisible"
    :title="'将发起' + state.Datas.length + '份合同'" :close-on-click-modal="false" width="1020px" top="120px">
    <div class="dp-f ai-c jc-sb mt-15 mb-15">
      <div class="sinputbox">
        <el-input v-model="state.searchKey.input" placeholder="搜索内容、联系方式等">
          <template #append>
            <div class=" h100 dp-fc">
              <span class="icon iconfont icon-sousuo cu-p" @click="seachClick"></span>
            </div>
          </template>
        </el-input>
      </div>
      <div class="dp-f">
        <oabutton class="searcML" width='100' height='34' title="文档下载" CSStype=1 @buttonclick="download" />
        <el-upload ref="uploadRef" class="upload-demo" action="#" :accept='import_accept' :http-request="xlsxHttpImport"
          multiple :show-file-list="false">
          <oabutton class="searcML" width='100' height='34' title="批量导入" CSStype=2 />
        </el-upload>
      </div>
    </div>
    <el-table :data="state.showData" class="mb-20 tablebox" :header-cell-style="{
      background: '#EAEAEA',
      color: config.table.color,
      fontWeight: config.table.fontWeight,
      fontSize: config.table.fontSize,
    }" row-key="id">
      <template v-for="(el, ind) in props.keys">
        <el-table-column :align="'center'" :prop="pinyin(el, { toneType: 'none' })" :label="el" />
      </template>
      <!-- <el-table-column :align="'center'" label="操作">
        <template #default="scope">
          <div class="dp-fc">
            <div class="Delete_span" @click="deteleClick(scope.$index)">
              删除
            </div>
          </div>
        </template>
      </el-table-column> -->
    </el-table>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="saveData"
          v-model:loading="dialogloading" />
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false" />
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, computed } from 'vue'
import { import_accept } from "@/utils/base/accept"
import { readExcel } from "@/utils/server/excelFile.js"
import { httpToken } from "@/utils/request";
import { validPhone } from "@/utils/server/validate.js";
import { downloadFileByContract } from '@/utils/server/upload'
import { pinyin } from 'pinyin-pro';
import qs from "qs";
import { handleMessage } from "@/utils/server/confirm";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const props = defineProps({
  keys: {
    default: {},
  },
  id: {
    default: 0
  }
});
const emit = defineEmits(['Emit'])
const state = reactive({
  showData: [],
  Datas: [],//批量导入数据
  searchKey: {
    input: '',
  },//搜索框绑定值
})
const seachClick=()=>{
  state.showData=state.Datas.filter(function (item) {
    for (let key in item) {
      if(item[key].indexOf(state.searchKey.input)!=-1){
        return item
      }
    }
  });
}
const download = () => {
  downloadFileByContract(props.id)
}
// pinyin('测试', { toneType: 'none' })
// 模板文件导入
const xlsxHttpImport = ((val) => {
  readExcel(val.file).then(res => {
    let resData = []
    res.forEach((item,ind) => {
      let itemData={}
      let validIs=true
      // if(!item['甲方手机号']){
      //   handleMessage('第'+(ind+1)+'行甲方手机号为空')
      //   validIs=false
      // }
      if(!item['乙方手机号']){
        handleMessage('第'+(ind+1)+'行乙方手机号为空')
        validIs=false
      }
      // if(!item['甲方公司名称']){
      //   handleMessage('第'+(ind+1)+'行甲方公司名称为空')
      //   validIs=false
      // }
      // if(!item['乙方公司名称']){
      //   handleMessage('第'+(ind+1)+'行乙方公司名称为空')
      //   validIs=false
      // }
      for (let key in item) {
        if(key=='甲方手机号'||key=='乙方手机号'){
          if(!validPhone(item[key])){
            handleMessage('第'+(ind+1)+'行'+key+'手机号格式错误')
            validIs=false
          }
        }
        itemData[pinyin(key, { toneType: 'none' })]=item[key]
      }
      if(validIs)
        resData.push(itemData)
    })
    if (resData.length == 0) {
      handleMessage('无符合条件数据')
      return
    }
    state.showData = resData
    state.Datas = resData
    // emit('Emit', resData)
  })
})
// 删除数据
const deteleClick = (ind) => {
  state.showData.splice(ind, 1)
  // state.Datas
  console.log('删除数据', ind)
}
// 保存数据
const saveData = () => {
  emit('Emit', state.Datas)
  dialogVisible.value = false
}
const show = (() => {
  state.showData = []
  state.Datas = []
  dialogloading.value = false
  dialogVisible.value = true
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/color/value.scss";

.iconfont {
  color: #dcdfe6;
}

::v-deep .el-input {
  height: 42px;
}

.tablebox {

  ::v-deep .el-scrollbar__view {
    max-height: 400px;
    overflow: auto;
  }
}

.sinputbox {
  ::v-deep .el-input__wrapper {
    box-shadow: none;
    border: 1px solid #dcdfe6;
    border-right: none;
  }

  ::v-deep .el-input-group__append {
    background-color: transparent;
    padding: 0 8px;
  }
}
</style>